.HotPink {
  background-color: hotpink !important; 
}

html, body {
  background-color: #282c34;
}

footer {
  margin-top: -35px;
  font-size: 20px;
}

.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.component {
  margin: 10px 10px 10px 10px !important;
  height: 75vh;
  border: whitesmoke solid 3px;
  background-color: #3e4c6d;
  overflow-y:auto;
}

.secondary-component {
  background-color: #515868; 
}

.playerColor {
  color: #33d9ff;
}

.botColor {
  color: #ff8000;
}

.neutralColor {
  color: whitesmoke;
}

button {
  vertical-align: baseline;
  padding: 0px !important;
  width: 80px !important;
  margin: 4px !important;
}

.buttonPlayer {
  background-color: #33d9ff !important;
}

.buttonBot {
  background-color: #ff8000 !important;
  vertical-align: baseline;
  opacity: 1 !important;
}

svg {
  vertical-align: middle;
}